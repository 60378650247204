
document.addEventListener('DOMContentLoaded', (event) => {
    Menu.listenOpen();
    objectFitImages();

    AOS.init({
        duration: 1000,
        once: true
    });

    MicroModal.init({
        disableScroll: true
    });

    PhotoSwipePlugin.init();
});
