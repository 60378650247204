
class Menu {

    /**
     * Set overflow hidden to body if menu is open (ban background-scrolling).
     */
    static listenOpen() {
        const checkbox = document.getElementById('nav-toggle'),
            navList = document.getElementsByClassName('nav')[0];

        checkbox.addEventListener('change', function(){

            if (checkbox.checked) {
                bodyScrollLock.disableBodyScroll(navList);
               //document.body.style.overflow = 'hidden';
            } else {
                bodyScrollLock.enableBodyScroll(navList);
               //document.body.style.overflow = '';
            }
        });
    }
}
